<template>
  <div>
    <list
      ref="refUserList"
      sort-by="UsuarioActivoId"
      refetch-records-name="usuarios"
      key-field="UsuarioActivoId"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fetchActivityUsersWithActiveShift"
      :sort-dir-desc="false"
    >
      <template #cell(nombreUsuarioAsignado)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.nombreCompleto)"
              variant="light-danger"
            />
          </template>
          <b-link
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.nombreCompleto }}
          </b-link>
          <small class="text-muted">@{{ data.item.nombreUsuario }}</small>
        </b-media>
      </template>
      <template #cell(fechaInicio)="data">
        <b-media v-if="stateTime.value" vertical-align="center">
          {{ data.item.fechaInicio | formatDateTime }}
        </b-media>
        <b-media v-else vertical-align="center">
          {{ data.item.fechaInicio | formatDate }}
        </b-media>
      </template>
      <template #cell(fechaFin)="data">
        <b-media v-if="stateTime.value" vertical-align="center">
          {{ data.item.fechaFin | formatDateTime }}
        </b-media>
        <b-media v-else vertical-align="center">
          {{ data.item.fechaFin | formatDate }}
        </b-media>
      </template>
    </list>
  </div>
</template>

<script>
import {
  BMedia,
  BAvatar,
  BLink,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import helpDeskService from '@/services/helpDesk.service'
import List from '@/components/List.vue'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    List,
    BMedia,
    BAvatar,
    BLink,
  },
  setup() {
    const {
      fetchActivityUsers,
      fetchActivityUsersWithActiveShift,
    } = helpDeskService()
    const dateTimeFormater = JSON.parse(localStorage.getItem('stateTime'))
    if (!dateTimeFormater.find(e => e.key === 'formatDateUsersActive')) {
      dateTimeFormater.push({ key: 'formatDateUsersActive', value: false })
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
    }
    const stateTime = ref(dateTimeFormater.find(e => e.key === 'formatDateUsersActive'))
    let actions = ref([])

    const changeStateTime = () => {
      stateTime.value.value = !stateTime.value.value
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
      const index = actions.value.findIndex(e => e.name === 'dateAndHour')
      actions.value[index].value = stateTime.value.value
    }
    const refUserList = ref(null)
    const filters = ref([
      {
        type: 'select',
        name: 'nombreUsuarioAsignado',
        label: i18n.t('AppHelpDesk.filters.userName'),
        value: '',
        options: [],
        domainName: 'MesaAyudaUsuarios',
        domainTextOptions: true,
      },
      {
        type: 'checkbox',
        label: i18n.t('Components.List.Filters.FormatDate'),
        value: 0,
        options: [],
      },
    ])
    const tableColumns = [
      {
        key: 'usuarioActivoId',
        label: i18n.t('AppHelpDesk.id'),
        sortable: true,
        sortKey: 'UsuarioActivoId',
      },
      {
        key: 'nombreUsuarioAsignado',
        label: i18n.t('AppHelpDesk.columns.user'),
        sortable: true,
        sortKey: 'NombreUsuarioAsignado',
      },
      {
        key: 'fechaInicio',
        label: i18n.t('AppHelpDesk.columns.shiftStart'),
        sortable: false,
      },
      {
        key: 'fechaFin',
        label: i18n.t('AppHelpDesk.columns.shiftEnd'),
        sortable: false,
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
        sortable: false,
      },
    ]
    const tableActions = ref([
      {
        name: 'information',
        label: i18n.t('AppHelpDesk.users.contactDetails'),
        aclAction: 'read',
        aclResource: 'MesaAyuda',
        routeName: 'apps-tickets-user-list-contact',
        params: ['usuarioActivoId'],
        icon: 'BookOpenIcon',
      },
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'edit',
        aclResource: 'MesaAyuda',
        routeName: 'apps-tickets-user-list-edit',
        params: ['usuarioActivoId'],
        icon: 'Edit3Icon',
      },
    ])
    actions = ref([
      {
        label: i18n.t('AppHelpDesk.activeUsers.add'),
        aclAction: 'create',
        aclResource: 'MesaAyuda',
        routeName: 'apps-tickets-user-list-add',
        icon: 'PlusCircleIcon',
      },
      {
        name: 'dateAndHour',
        aclAction: 'read',
        aclResource: 'AccionesMejora',
        icon: 'CalendarIcon',
        value: stateTime.value.value,
        click: changeStateTime,
      },
    ])

    return {
      refUserList,
      filters,
      tableColumns,
      tableActions,
      actions,
      fetchActivityUsers,
      fetchActivityUsersWithActiveShift,
      avatarText,
      stateTime,
    }
  },
}

</script>
